<template>
  <div class="main">
    <div class="table">
      <div class="thead">
        <div class="tr">
          <div class="overlayHead">
            <div class="dateInner">积分池:{{ total }}</div>
          </div>
        </div>
        <div class="tr">
          <div class="td">时间</div>
          <div class="td">类型</div>
          <div class="td">用户</div>
          <div class="td">操作人</div>
          <div class="td">积分</div>
        </div>
      </div>
      <div class="tbody" style="max-height:calc(100vh - 130px);overflow:auto">
        <div class="tr" v-for="item in data" :key="item.id">
          <div class="td">{{ item.input_time?.substring(5, 16) || '-' }}</div>
          <div class="td">{{ item.description || '-' }}</div>
          <div class="td">{{ item.sjhm_user?.xm || '-' }}</div>
          <div class="td">{{ item.cz_sjhm_user?.xm || '-' }}</div>
          <div class="td">{{ item.zzjf || '-' }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import axios from 'axios';
import dayjs from 'dayjs';
import { showConfirmDialog, showNotify, showToast } from 'vant';
import { mapActions, mapState } from 'vuex';

export default {
  data() {
    return {
      active: 0,
      value: "",
      store: null,
      loading: false,
      data: [],
      total: '',
      filter: {
        search: '',
      }
    };
  },
  async created() {
    // await this.getStore();
    this.getData();
  },
  computed: {
    ...mapState([]),
  },
  watch: {
    $route(route) {
      this.filter.search = route.query.search || '';
      this.getData();
    }
  },
  methods: {
    ...mapActions([]),


    async getData(val = '') {
      let { data: res } = await axios.get("/api/api/get_md_zzjf", {
        params: {
          name: this.filter.search,
          sjhm: this.$route.query.sjhm
        }
      });
      this.data = res.data;
      this.total = res.total_zzjf || '0';
    },

    async getStore() {
      let { data: res } = await axios.get("/api/api/get_guanli_mendian", {
        params: {
          sjhm: this.$route.query.sjhm
        }
      });
      this.store = res.data?.[0] || null;
    },
  },
  components: {}
}
</script>
<style lang="less" scoped>
.main {
  flex: 1;
  overflow: auto;
  width: 100%;
}

.table {
  text-align: center;

  .thead {
    color: #fff;
    font-size: 14px;

    .tr {
      display: flex;
      min-height: 32px;
      align-items: center;
      justify-content: space-between;
      background: #3395a2;

      .overlayHead {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 44px;
        width: 100%;

        .btn {
          padding: 0 24px;
          height: 24px;
          display: flex;
          align-items: center;
        }
      }

      .td {
        &:first-child {
          flex: 1.5;
        }

        flex: 1;
      }
    }
  }

  .tbody {
    color: #333;
    font-size: 14px;

    .tr {
      display: flex;
      min-height: 44px;
      align-items: center;
      justify-content: space-between;
      border: 1px solid #e5e6e7;

      .td {
        padding: 6px 0;
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:first-child {
          flex: 1.5;
        }

        &.red {
          color: red;
        }
      }
    }
  }
}
</style>
